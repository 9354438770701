import EmblaCarousel from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";

const emblaNode = document.querySelector(".embla");
const viewportNode = emblaNode.querySelector(".embla__viewport");
const prevButtonNode = emblaNode.querySelector(".embla__prev");
const nextButtonNode = emblaNode.querySelector(".embla__next");
const options = { loop: true };
const autoplayOptions = {
	playOnInit: true,
	delay: 5000,
	stopOnInteraction: false,
};

// Initialize the carousel
const embla = EmblaCarousel(viewportNode, options, [Autoplay(autoplayOptions)]);

// Add click listeners
prevButtonNode.addEventListener("click", embla.scrollPrev, false);
nextButtonNode.addEventListener("click", embla.scrollNext, false);
